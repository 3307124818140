import './App.css';

function App() {
  return (
    <div className="App">
      <iframe 
        id="myFrame" 
        className='App-iframe' 
        src='https://nikolinasafar18.wixsite.com/my-site-1'
        title='My-site'
      />
    </div>
  );
}

export default App;
